export default {
  details: {
    title: "Clever Brainz",
    tagLine: "Software Development",
    description: "Mobile & Web Application Development",
    url: "https://www.cleverbrainz.com",
  },
  assets: {
    avatar: "/images/common/avatar.png",
    favicon: "/images/common/favicon.png",
    logo: "/images/common/logo.png",
  },
  socialLinks: {
    twitter: "@CleverBrainz",
    facebook: "@cleverbrainz0"
  },
};
