import React, { FC } from "react";
import { Box, Grid, Stack, HStack, Text, Image, useColorMode } from "@chakra-ui/react";
import IArticle from "types/article";
import IPublication from "types/publication";
import IProject from "types/project";
import LazyLoad from "react-lazyload";
import dynamic from "next/dynamic";

const Jumbotron = dynamic(import("components/pages/index/jumbotron"));
const NewsletterSubscriptionForm = dynamic(
  import("components/pages/index/newsletter-subscription-form")
);
const WeAre = dynamic(
  import("components/pages/index/we-are")
);
const Services = dynamic(
  import("components/pages/index/services")
);
const Testimonials = dynamic(
  import("components/pages/index/testimonials")
);
const Articles = dynamic(
  import(/* webpackChunkName: "Articles" */ "components/layouts/articles")
);
const Publications = dynamic(
  import(/* webpackChunkName: "Publications" */ "components/layouts/publications")
);
const Projects = dynamic(
  import(/* webpackChunkName: "Projects" */ "components/pages/index/projects")
);
const SocialLinks = dynamic(
  import(/* webpackChunkName: "SocialLinks" */ "components/social-links")
);
const Navbar = dynamic(
  import(/* webpackChunkName: "Navbar" */ "components/navbar")
);
const Footer = dynamic(
  import(/* webpackChunkName: "Footer" */ "components/footer")
);

interface Props {
  publications: IPublication[];
  projects: IProject[];
}

const Page: FC<Props> = ({
  publications = [],
  projects = [],
}) => {

  const { colorMode } = useColorMode();
  const cardBgColor = { light: "white", dark: "gray.900" };
  const cardColor = { light: "gray.900", dark: "white" };

  // const sortedArticles = articles.sort(
  //   (a: IArticle, b: IArticle) =>
  //     Number(new Date(b.date)) - Number(new Date(a.date))
  // );

  return (
    <>
      <Box as="section">
        <Jumbotron />
      </Box>
      <SocialLinks />
      <Navbar />
      <Box>
        <Box maxW="7xl" mx="auto" px={4} py={8}>
          <Grid templateColumns={["1fr", "1fr", "1fr", "1fr"]} gap={8}>
            <WeAre />
            <Services />
            <Box as="section">
              <LazyLoad once offset={300}>
                <Projects projects={projects.slice(0, 3)} />
              </LazyLoad>
            </Box>
            <Testimonials />
            {/* <Box>
              <LazyLoad once offset={300}>
                <NewsletterSubscriptionForm />
              </LazyLoad>
            </Box> */}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Page;
